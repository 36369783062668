import React, { useEffect } from "react";
import Header from "../components/Header";
import Window from "../components/Window";
import Footer from "../components/Footer";
import Work from "../components/Work";
import Slider from "../components/slider";
import Blinking from "../components/Blinking";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Daphne Bonilla | Portfolio";
  }, []);

  return (
    <div>
      <Header />
      <div className="divider divider-minus-body" />
      <Window />
      <Work />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Blinking />
      <Slider />
      <Footer />
    </div>
  );
};

export default Home;
