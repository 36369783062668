import React from "react";
import { Link } from "react-router-dom";
import "./styles/nav.css";

const Nav = () => {
  return (
    <div className="container">
      <nav>
        <ul>
          <li>
            <Link to="/" className="link">
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about" className="link">
              ABOUT
            </Link>
          </li>
          <li>
            <Link to="/portfolio" className="link">
              PORTFOLIO
            </Link>
          </li>
          <li>
            <Link to="/uxportfolio" className="link">
              UX DESIGN
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
