import { useEffect } from "react";
import Footer from "../components/Footer";
import Work from "../components/Work";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Daphne Bonilla | Portfolio | Web developer | Programming | Design ";
  }, []);

  return (
    <>
      <Work />
      <Footer />
    </>
  );
};

export default Portfolio;
