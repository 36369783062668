import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/slider.css";

const data = [
  {
    id: 1,
    title: "Create Mobile Mockups using Figma.",
    image: "/slide1.png",
  },
  {
    id: 2,
    title:
      "Create responsive designs to allow content to flow across all screen resolutions and sizes.",
    image: "/slide2.png",
  },

  {
    id: 3,
    title:
      "Create low-fi prototypes to get ideas out quickly and leave room for exploration & hi-fi prototypes to test a design that looks like a real product",
    image: "/slide8.png",
  },
  {
    id: 3,
    title:
      "Create site maps to incorporate clear information architecture into designs",
    image: "/slide7.png",
  },
  {
    id: 3,
    title: "Create personas to identify patterns of behavior in users",
    image: "/slide5.png",
  },
  {
    id: 3,
    title: "Create digital wireframes during ideation process",
    image: "/slide3.png",
  },
  {
    id: 3,
    title: "Create journey maps to help think and feel like the user",
    image: "/slide6.png",
  },
  {
    id: 3,
    title: "Create competitive audits to explore ideas for designs ",
    image: "/slide9.png",
  },
  {
    id: 3,
    title: "Conduct UX research to add realistic context to the design process",
    image: "/slide10.png",
  },
  {
    id: 3,
    title: "Google UX Design Certified 2023",
    image: "/slide11.png",
  },
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(
      () =>
        setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1)),
      2500
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <div className="row">
        {/* TEXT CONTAINER */}
        <div className="col-md-6">
          <div className="text-center text-sm-start mt-2">
            <h1
              className={`display-2 text-sm ${
                data[currentSlide].id <= 3 ? "smaller-text" : ""
              }`}
            >
              {data[currentSlide].title}
            </h1>
            {/* Update the onClick handler to navigate to "/uxportfolio" */}
            <button
              className="btn btn-danger btn-lg mt-3"
              onClick={() => navigate("/uxportfolio")} // Navigate to the "/uxportfolio" route
            >
              See Case Studies
            </button>
          </div>
        </div>
        {/* IMAGE CONTAINER */}
        <div className="col-md-6">
          <img src={data[currentSlide].image} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Slider;
