import React from "react";
import { daphne } from "../assets/data";
import "./styles/window.css";

const Window = () => {
  return (
    <div id="about" className="container divider-minus">
      <div className="window">
        <div className="window-header">
          <div className="dots">
            <div className="window-dot-1" />
            <div className="window-dot-2" />
            <div className="window-dot-3" />
          </div>
          <div className="window-title">About Me</div>
          <div className="cmd" />
        </div>
        <div className="window-body">
          <div className="window-body-content">
            <span>{">"}</span> Daphne.currentLocation
          </div>
          <div className="window-body-info mt-5">"{daphne.location}"</div>
          <div className="window-body-content mt-25">
            <span>{">"}</span> Daphne.contactInfo
          </div>
          <div className="window-body-info mt-5">
            ["
            <a href={`mailto:${daphne.contacts.email}`}>
              {daphne.contacts.email}
            </a>
            ", "
            <a href={daphne.contacts.linkedin} target="_blank" rel="noreferrer">
              LinkedIn
            </a>
            ", "
            <a href={daphne.contacts.github} target="_blank" rel="noreferrer">
              GitHub
            </a>
            "]
          </div>
          <div className="window-body-content mt-25">
            <span>{">"}</span> Daphne.CV
          </div>
          <div className="window-body-info mt-5">
            "
            <a
              href={"/assets" + daphne.resume}
              target="_blank"
              rel="noreferrer"
            >
              Daphne_SWE_Resume.pdf
            </a>
            "
          </div>
          <div className="window-body-content mt-25">
            <span>{">"}</span> Daphne.interests
          </div>
          <div className="window-body-info mt-5">
            [
            {daphne.interests
              .map((interest) => '"' + interest + '"')
              .join(", ")}
            ]
          </div>
          <div className="window-body-content mt-25">
            <span>{">"}</span> Daphne.education
          </div>
          <div className="window-body-info mt-5">"{daphne.education}"</div>
          <div className="window-body-content mt-25">
            <span>{">"}</span> Daphne.skills
          </div>
          <div className="window-body-info mt-5">
            [{daphne.skills.map((skill) => '"' + skill + '"').join(", ")}]
          </div>
          <div className="window-body-content mt-25 flex">
            <span>{">"}</span> <div className="blink text-cursor-light" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Window;
