import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Window from "../components/Window";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Daphne Bonilla | About | Web developer | Programming | Design";
  }, []);

  return (
    <>
      <Header />
      <div className="divider divider-minus-body" />
      <Window />
      <Footer />
    </>
  );
};

export default About;
