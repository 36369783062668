import React from "react";
import "./styles/work.css";

const Blinking = () => {
  const blinkingStyle = {
    marginTop: "-150px", // Adjust the top margin as needed
    marginBottom: "40px", // Adjust the bottom margin as needed
  };

  return (
    <div className="container">
      <div className="work" id="work">
        <div className="work-title">
          <div className="arrow-item" style={blinkingStyle}>
            <h1 className="arrow-text flex">
              <span>{">"}UX Design</span>
              <div className="blink text-cursor" />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blinking;
