import { useEffect } from "react";
import Footer from "../components/Footer";
import Ux from "../components/Ux";
import Slider from "../components/slider"; // Import the Slider component

const Uxportfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Daphne Bonilla | Portfolio | Web developer | Programming | Design ";
  }, []);

  return (
    <>
      <Slider />
      <br />
      <Ux />
      <Footer />
    </>
  );
};

export default Uxportfolio;
