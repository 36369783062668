const daphne = {
  location: "Seattle, WA",
  contacts: {
    email: "daphne.ari.b@gmail.com",
    linkedin: "https://www.linkedin.com/in/daphnebonilla/",
    github: "https://github.com/Daphne271991/",
  },
  resume: "/Daphne_SWE_Resume.pdf",
  interests: ["Web Development", "Design", "Animals", "Philantropy"],
  education:
    "Bachelors Degree in Journalism & Emerging Media, Kennesaw State University",
  skills: ["React.js", "HTML", "CSS", "JavaScript", "GitHub"],
};

const projects = [
  {
    title: "Twitter Clone | Full Stack Social Media App",
    github: "https://github.com/Daphne271991/snapgram",
    url: "https://main--spectacular-kringle-6c3ea7.netlify.app/",
    stack: ["React", "Typescript", "Tailwind", "ReactQuery"],
    image: "social.png",
  },
  {
    title: "eCommerce Price Tracker | Data Scraping using Next.js 14 ",
    github: "https://github.com/Daphne271991/pricewise",
    url: "https://pricewise-seven-zeta.vercel.app/",
    stack: ["Next.js", "Typescript", "Tailwind", "MongoDB", "BrightData"],
    image: "scrapper.png",
  },
  {
    title: "Animated Portfolio Website | Using React & Framer Motion",
    github: "https://github.com/Daphne271991/animated-portfolio/",
    url: "https://calm-chimera-1ecbeb.netlify.app/",
    stack: ["React", "Framer Motion", "Sass"],
    image: "animatedp.png",
  },
  {
    title: "Youtube Clone | Using Material UI & Rapid API",
    github: "https://github.com/Daphne271991/youtube_clone",
    url: "https://candid-meerkat-545ee9.netlify.app/",
    stack: ["React", "MUI", "RapidAPI"],
    image: "youtube.png",
  },
  {
    title: "Fitness App | Using Material UI & Rapid API",
    github: "https://github.com/Daphne271991/fitness_app",
    url: "https://zippy-cannoli-56a1bf.netlify.app/",
    stack: ["React", "MUI", "RapidAPI"],
    image: "fitness.png",
  },
  {
    title: "Movie Rating App | Using Redux ",
    github: "https://github.com/Daphne271991/movie-app",
    url: "https://main--warm-dusk-3351a0.netlify.app/",
    stack: ["Redux", "React", "Sass"],
    image: "movieapp.png",
  },
  {
    title: "Nike UI | Using Tailwind with Vite ",
    github: "https://github.com/Daphne271991/second_nike",
    url: "https://dainty-cranachan-ed5d1b.netlify.app/",
    stack: ["React", "Tailwind", "Vite"],
    image: "nikeui.png",
  },
  {
    title: "AI Article Summarizer | Using Next JS & Redux",
    github: "https://github.com/Daphne271991/ai_summarizer",
    url: "https://dancing-tartufo-26a1a0.netlify.app/",
    stack: ["Next.js", "Vite", "Redux Toolkit", "API", "Tailwind", "React"],
    image: "ai.png",
  },
  {
    title: "Responsive React Admin Panel | Using Rechart Library",
    github: "https://github.com/Daphne271991/admin-dashboard/",
    url: "https://scintillating-sunflower-2ee3fd.netlify.app",
    stack: ["React", "Sass"],
    image: "admin.png",
  },
  {
    title: "Travel UI| Using React, Next JS & Tailwind",
    github: "https://github.com/Daphne271991/travel_app",
    url: "https://genuine-lamington-9cee77.netlify.app/",
    stack: ["Next.js", "React", "Tailwind"],
    image: "camp.png",
  },
  {
    title: "Pet Match UI| Using Bootstrap",
    url: "https://resonant-pavlova-ec1ac7.netlify.app/",
    github: "https://github.com/Daphne271991/petmatch-bootstrap",
    stack: ["HTML", "Bootstrap", "CSS"],
    image: "petmatch.png",
  },

  {
    title: "Serverless E-commerce App | Using Rest API, MongoDB & JWT",
    github: "https://github.com/Daphne271991/e-commerce-backend",
    stack: ["Node.js", "MongoDB", "JWT"],
    image: "backend.png",
  },
  {
    title: "Space X Responsive Website Clone | Using Javascript",
    github: "https://github.com/Daphne271991/spacex-website",
    url: "https://enchanting-genie-0f6dd0.netlify.app/",
    stack: ["JS5", "HTML", "CSS"],
    image: "spacex.png",
  },
  {
    title: "Food Delivery App UI | Using Next JS & Tailwinf",
    github: "https://github.com/Daphne271991/rest-app",
    url: "https://legendary-panda-fb1186.netlify.app",
    stack: ["Next.js", "React", "Tailwind"],
    image: "restui.png",
  },
  {
    title: "Barbie Responsive Webpage | Using Javascript",
    github: "https://github.com/Daphne271991/barbie-project",
    url: "https://unique-melomakarona-5464fb.netlify.app/",
    stack: ["JS5", "HTML", "CSS"],
    image: "barbie.png",
  },
  {
    title: "Dictionary App | Using React & API",
    github: "https://github.com/Daphne271991/dictionary-project",
    url: "https://lambent-caramel-acaabb.netlify.app/",
    stack: ["React", "HTML", "CSS", "API"],
    image: "dictionary.png",
  },
  {
  title: "Dice Game App | Using Javascript",
  github: "https://github.com/Daphne271991/dice_challenge",
  url: "https://app.netlify.com/sites/eloquent-kashata-44f039/overview",
  stack: ["Javascript", "HTML", "CSS"],
  image: "dice.png",
},

  {
    title: "React Weather App | Using React & API",
    url: "https://cerulean-zuccutto-341625.netlify.app",
    github: "https://github.com/Daphne271991/weather-react",
    stack: ["React", "API", "CSS", "HTML"],
    image: "weather.png",
  },
  {
    title: "Data Generator | Using Next JS & API",
    url: "https://idyllic-salmiakki-6a3f4b.netlify.app",
    github: "https://github.com/Daphne271991/nextjs-project",
    stack: ["Next.js", "API", "React", "API", "Tailwind"],
    image: "nextjs.png",
  },

  {
    title: "World Clock | Using React & API",
    url: "https://neon-semifreddo-4c9a91.netlify.app/",
    github: "https://github.com/Daphne271991/world-clock",
    stack: ["React", "API", "CSS", "HTML"],
    image: "clock.png",
  },
];
const uxprojects = [
  {
    title: "Adopt Match Case Study",
    url: "https://docs.google.com/presentation/d/1EEnd-tyWfjQLQzyjx73gq3fEYULJiL8_/edit?usp=sharing&ouid=112707240290343192894&rtpof=true&sd=true",
    stack: ["Figma", "AdobeXD"],
    image: "ux2.png",
  },
  {
    title: "NFL Fan Experience App Case Study",
    url: "https://docs.google.com/presentation/d/1BO96tOWVze9TmZppJTehBE2XSn4p4Azi/edit?usp=sharing&ouid=112707240290343192894&rtpof=true&sd=true",
    stack: ["Figma", "AdobeXD"],
    image: "ux1.png",
  },
];

export { daphne, projects, uxprojects };
